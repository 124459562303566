import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class AdditionalInformationTypesRepository extends BaseRepository {

    baseUrl = 'management/additional-information/additional-information-types';

    /**
     * Returns active records
     *
     * @returns {*}
     */
    active() {
        return this.all();
    }

}
