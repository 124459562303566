import { inject }                           from 'aurelia-framework';
import { PLATFORM }                         from 'aurelia-pal';
import { BaseFormViewModel }                from 'base-form-view-model';
import { FormSchema }                       from 'modules/management/additional-information/additional-informations/form-schema';
import { AdditionalInformationsRepository } from 'modules/management/additional-information/additional-informations/services/repository';
import { LotInterventionsRepository }       from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { AppContainer }                     from 'resources/services/app-container';

@inject(AppContainer, AdditionalInformationsRepository, FormSchema, LotInterventionsRepository)
export class CreateAdditionalInformation extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'management.additional-information.additional-informations.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    create = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param lotInterventionsRepository
     */
    constructor(appContainer, repository, formSchema, lotInterventionsRepository) {
        super(appContainer);

        this.repository                 = repository;
        this.formSchema                 = formSchema;
        this.lotInterventionsRepository = lotInterventionsRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/additional-information/additional-informations/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('management.additional-information.additional-information-types.index') && super.can([
            'management.additional-information.additional-informations.manage',
            'management.additional-information.additional-informations.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        // Initialize the model
        this.initializeModel();

        // Fetch the next number and selected lot information
        const { next_number }        = await this.repository.nextNumber();
        const selectedLotInformation = this.appContainer.authenticatedUser.getAllSelectedLotInformation();

        // Assign base lot information
        this.assignBaseLotInformation(next_number, selectedLotInformation);

        // Handle duplication scenario if duplicatingId is present
        if (params.duplicatingId) {
            await this.handleDuplication(params.duplicatingId);
        }

        // Handle lot intervention scenario if lotInterventionId is present
        if (params.lotInterventionId) {
            await this.assignNewLotInterventionInfo(params.lotInterventionId);
        }

        this.schema        = this.formSchema.schema(this, false, true);
        this.lotInfoSchema = this.formSchema.lotInfoSchema(this);
        this.globalSchema  = this.formSchema.globalSchema(this);
    }

    /**
     * Initializes the model and sets the initial state
     */
    initializeModel() {
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
    }

    /**
     * Assigns base lot information to the model
     *
     * @param nextNumber
     * @param selectedLotInformation
     */
    assignBaseLotInformation(nextNumber, selectedLotInformation) {
        const baseInfo = {
            number:                nextNumber,
            entity_name:           selectedLotInformation.entity_name,
            entity_id:             selectedLotInformation.entity_id,
            team_name:             selectedLotInformation.team_name,
            team_id:               selectedLotInformation.team_id,
            concession_name:       selectedLotInformation.concession_name,
            concession_id:         selectedLotInformation.concession_id,
            lot_intervention_name: selectedLotInformation.lot_intervention_name,
            lot_intervention_id:   selectedLotInformation.lot_intervention_id,
        };

        this.initialModel.assign(baseInfo);
        this.model.assign(baseInfo);
    }

    /**
     * Handles the duplication of a model
     *
     * @param duplicatingId
     */
    async handleDuplication(duplicatingId) {
        const duplicatingModel = await this.repository.find(duplicatingId);

        // Remove specific properties that shouldn't be duplicated
        const sanitizedModel = this.sanitizeDuplicatingModel(duplicatingModel);

        // Assign the sanitized model to both initialModel and model
        this.initialModel.assign(sanitizedModel);
        this.model.assign(sanitizedModel);
    }

    /**
     * Sanitizes the model to remove fields that shouldn't be duplicated
     *
     * @param model
     * @returns {object}
     */
    sanitizeDuplicatingModel(model) {
        // destructuring the model to remove specific properties
        const { number, file_id, existing_file, ...sanitizedModel } = model;

        return sanitizedModel;
    }

    /**
     * Assigns the fetched lot intervention information to the model
     *
     * @param lotInterventionId
     * @return {Promise<void>}
     */
    async assignNewLotInterventionInfo(lotInterventionId) {
        const lotIntervention = await this.lotInterventionsRepository.find(lotInterventionId);

        const interventionInfo = {
            lot_intervention_name: lotIntervention.designation,
            lot_intervention_id:   lotIntervention.id,
            concession_id:         lotIntervention.concession_id,
            concession_name:       lotIntervention.concession_name,
        };

        this.initialModel.assign(interventionInfo);
        this.model.assign(interventionInfo);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                this.handleResponse(response, 'management.additional-information.additional-informations.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

}
