import { inject }                               from 'aurelia-framework';
import { AdditionalInformationFilesRepository } from 'modules/management/additional-information/additional-informations/services/files-repository';
import { Downloader }                           from 'resources/services/downloader';

@inject(AdditionalInformationFilesRepository, Downloader)
export class AdditionalInformationFileCustomListingCell {
    /**
     * Constructor.
     *
     * @param filesRepository
     * @param downloader
     */
    constructor(filesRepository, downloader) {
        this.filesRepository = filesRepository;
        this.downloader      = downloader;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.column = model.column;
        this.row    = model.row;
    }

    /**
     * Downloads the file
     */
    downloadFile(model) {
        // remove the file extension from the file name
        const fileName =  this.row ? this.row.file.display_name.split('.').slice(0, -1).join('.') : model.existing_file.display_name.split('.').slice(0, -1).join('.');

        this.filesRepository
            .download(this.row ? this.row.file_id : model.file_id)
            .then((blob) => this.downloader.download(blob, fileName, this.row ? this.row.file.extension : model.existing_file.extension));
    }
}
